.navbar {
  width: 100%;
  height: 100px;
  background-color: #827397;
  display: flex;
  flex-direction: row;
}

.navbar .leftSide {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 130px;
}

.navbar .leftSide img {
  width: 46px;
  padding-left: 8px;
}

.logoText {
  font-size: 42px;
  font-weight: bold;
  font-family: fantasy;
  /* font-family: monospace; */
  /* font-family: cursive; */
  color: #E9D5DA;
}

.navbar .rightSide {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar a {
  color: white;
  text-decoration: none;
  margin: 20px;
  font-weight: bold;
}

.navbar a:hover {
  color: #E9D5DA;
}

.navbar .rightSide button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

.navbar .rightSide svg {
  font-size: 40px;
}

.rightSide .signUp {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #363062;
  height: 30px;
  width: 80px;
  border-radius: 5px;
}

.rightSide .cartIcon {
  width: 25px;
  height: 25px;
}

.rightSide span.cartLength {
  font-size: 14px;
  font-weight: 700;
  color: #E9D5DA;
}

.cartNum {
  display: flex;
}

.navbar #open {
  padding-left: 0px;
}
.navbar #open img {
  display: none;
}
.navbar #open p {
  display: none;
}

.navbar #open a {
  width: 70px;
  margin: 5px;
}
.navbar #open .hiddenLinks {
  display: inherit;
  margin-left: 30px;
}

.navbar #close img {
  display: inherit;
}
.navbar #close .hiddenLinks {
  display: none;
}

@media only screen and (max-width: 900px) {
  /* .navbar .leftSide a {
    display: none;
  } */
  .navbar .rightSide a {
    width: 60px;
  }
  .navbar .leftSide {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 645px) {
  .navbar .rightSide a {
    display: none;
  }

  .navbar .rightSide {
    justify-content: flex-end;
    padding-right: 30px;
  }

  .navbar .rightSide button {
    display: inherit;
  }
}

@media only screen and (min-width: 600px) {
  .navbar .leftSide a {
      display: none;
  }

  .navbar .rightSide button {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }
}

.menu {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.menu .menuTitle {
  font-family: cursive;
  font-size: 56px;
}

.menuList {
  width: 80vw;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  margin-bottom: 20px;
}

.menuItem {
  border-radius: 15px;
  width: 300px;
  height: 390px;
  margin: 20px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}
.menuItem:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.menuItem div {
  border-radius: 15px 15px 0 0;
  width: 100%;
  height: 270px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.menuItem h1 {
  font-size: 18px;
}

.menuItem h1,
.menuItem p {
  margin-left: 20px;
}

.menuItem button {
  width: 100%;
  height: 38px;
  border: none;
  background-color: #363062;
  color: whitesmoke;
  font-size: 14px;
  cursor: pointer;
  border-radius: 6px;
}
.menuItem button:hover {
  font-size: 14.5px;
  font-weight: 300;
}
.menuItem button:active {
  transform: translateY(1px);
}

@media only screen and (max-width: 1300px) {
  .menuList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .menuList {
    grid-template-columns: 1fr;
  }
}

.about {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.aboutTop {
  width: 100%;
  height: 55%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.aboutBottom {
  display: flex;
  flex-direction: column;
  height: 55%;
  align-items: center;
  margin: 46px;
}

.aboutBottom p {
  font-size: 20px;
}

.aboutBottom h1 {
  font-weight: 400;
  font-size: 56px;
  color: black;
  height: 30px;
  display: flex;
  justify-content: center;
  transform: translateY(-20px);
}

/* @media only screen and (max-width: 645px) {
  .home {

  } */

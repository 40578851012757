.footer {
  width: 100%;
  height: 200px;
  background-color: #827397;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
}

.socialMedia svg {
  color: white;
  margin: 20px;
  font-size: 70px;
  cursor: pointer;
}
.socialMedia svg:hover {
  color: #E9D5DA;
}

.footer p {
  color: white;
}

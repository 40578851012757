.cart {
  width: 65%;
  height: 100vh;
  margin: 50px auto;
  text-align: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.cartItems-header {
  font-family: cursive;
  font-size: 56px;
}

.cartItems-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  padding-top: 20px;
}

.cartItems-list {
  display: flex;
  align-items: center;
  padding: 20px 0 20px 20px;
  font-size: 20px;
  font-weight: 600;
  margin-left: 5%;
}

.cartItems-image {
  border-radius: 15px;
  width: 100px;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
}

.cartItems-name, .cartItems-function {
  width: 38%;
}

.cartItems-add, .cartItems-remove {
  width: 30px;
  margin-right: 15px;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  font-size: 18px;
  color: white;
  cursor: pointer;
}

.cartItems-add {
  background-color: #363062;
}
.cartItems-remove {
  background-color: #363062;
}

.cartItems-add:active {
  transform: translateY(1.2px);
}
.cartItems-remove:active {
  transform: translateY(1.2px);
}

.itemPrice,
.deliveryPrice,
.totalPrice {
  display: flex;
}

.itemPrice-name,
.deliveryPrice-name,
.totalPrice-name {
  padding: 20px 0;
  padding-left: 27%;
  font-size: 20px;
  font-weight: 600;
}

.itemPrice-num,
.deliveryPrice-num,
.totalPrice-num {
  padding: 20px 0;
  font-size: 20px;
  font-weight: 600;
}
.itemPrice-num {
  padding-left: 54%;
}
.deliveryPrice-num {
  padding-left: 51.5%;
}
.totalPrice-num {
  padding-left: 53%;
}

.checkout {
  width: 55%;
  height: 38px;
  border: none;
  background-color: #363062;
  color: whitesmoke;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
}

.checkout:hover {
  font-size: 16.5px;
  font-weight: 400;
}

.checkout:active {
  transform: translateY(1px);
}
